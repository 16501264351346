<template>
  <div class="" style="text-align: left; min-height: 75vh;">
    <div class="container" style="padding-top: 50px; padding-bottom: 50px;">
      <span>
        <router-link
          class=""
          style="text-decoration: none;"
          id=""
          :to="{ name: 'Landing', params: {} }"
        >
          <span class="">Home</span>
        </router-link>
        |
        <span style="color: #F5AD0B;">About us</span>
      </span>
      <h1 v-if="about" style="font-weight: bold;">{{ about.title }}</h1>
    </div>
    <div class="container-fluid" style="background: #F7F8FC;">
      <div v-if="about" class="container" v-html="about.text"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "About",
  data() {
    return {
      about: null,
      idList: JSON.parse(localStorage.getItem("avLanguages")),
    };
  },
  async mounted() {
    this.getAbout();
  },

  methods: {
    getAbout() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      let dynamicId = this.idList.find((e) => e.code === this.$i18n.locale).id;

      fetch(
        this.apiReq + "/api/v1/page/about?languageId=" + dynamicId,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            this.about = result.page;
          } else {
            console.log(result);
          }
        })
        .catch((error) => console.log("error", error));
    },
  },

  components: {},
  props: {},

  computed: {
    ...mapGetters(["apiReq"]),
  },
};
</script>

<style scoped></style>
