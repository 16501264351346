<template>
  <div id="app">
    <Navbar />
    <router-view :key="$route.path" />
    <Footer />
  </div>
</template>

<script>
// import { mapActions, mapGetters} from 'vuex';
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

export default {
  name: "App",
  data() {
    return {};
  },

  async mounted() {},

  methods: {},

  components: {
    Navbar,
    Footer,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;600;700&display=swap");

#app {
  font-family: "Work Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

p {
  font-size: 18px;
}

.mainButton {
  background: #f4a900;
  border: none;
}

.mainButton:hover {
  background: #f5ad0b;
}
.mainButton:focus {
  background: #f4a900;
}

.defText {
  color: white;
}

.defText:hover {
  color: #f5ad0b;
}

.bg {
  background: #f7f8fc;
}

#arrow1 {
  padding-left: 5px;
  transition-duration: 300ms;
}

#arrow:hover #arrow1 {
  padding-left: 12px;
  transition-duration: 300ms;
}
</style>
