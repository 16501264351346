export default {
    actions: {

       async getProfileG ({commit, state},){
            var requestOptions = {
            method: 'GET',
            redirect: 'follow'
            };
    
            fetch( state.httpReq +  "/profile/" + state.cookiesObject.token, requestOptions)
            .then(response => response.json())
            .then (result => {
                const account =  result.account;
                // console.log(result.account)
                commit('userData', account);
        })
            .catch(error => console.log('error', error));
          },
    
    //    async setLeagueId({ commit }, leagueIden){
    //             commit('newLeagueId', leagueIden);
    //         },
               
            // async getArray({ commit, state},){
            // const res = await fetch('https://sportapi.zuniac.com/favorites?timezone=%2B04%3A00&token=' + state.token.token);
            // const data = await res.json();
            // const favs = data.teams;
            // const favsT = data.tournaments;
            // const favsTe = data.matches;
            //     commit('updateArray', favs);
            //     commit('updateArrayT', favsT);
            //     commit('updateArrayTe', favsTe);
            // },
           
            // async getArrayT({ commit, state},){
            //   const res = await fetch('https://sportapi.zuniac.com/favorites?timezone=%2B04%3A00&token=' + state.token.token);
            //   const data = await res.json();
            //   const favsT = data.tournaments;
            //       commit('updateArrayT', favsT);
            //   },  


        //    async removeFavorite( { dispatch, state}, value){
        //       var myHeaders = new Headers();
        //        myHeaders.append("Cookie", "__cfduid=d0fd030adfcb3ad72f89a1229479eea4c1595402997");
   
        //          var requestOptions = {
        //            method: 'DELETE',
        //            headers: myHeaders,
        //            redirect: 'follow'
        //          };
                 
   
        //          fetch("https://sportapi.zuniac.com/favorite?elementId="+ value.value +"&elementType=" + value.type + "&token=" + state.token.token, requestOptions)
        //          .then(response => response.text())
        //            .then(result => {
        //              console.log(1, result);
        //               dispatch("getArray");
        //            })
        //            .then( console.log(value))

        //            .catch(error => console.log('error', error));
        //     },     
          
    },
    mutations: {
    //   newLeagueId: (state, leagueIden) => state.leagueIDe = leagueIden,
    userData:(state, account) => state.account = account,

    //   updateArray(state, favs){
    //     state.favs = favs;
    //   },

    },
    state: {
        cookiesObject: document.cookie.split(';').map(cookie => cookie.split('=')).reduce((accumulator, [key, value]) => ({...accumulator, [key.trim()]: decodeURIComponent(value)}),{}),
        httpReq: 'https://sso.zunilingo.com',
        apiReq: 'https://api.zunilingo.com',
        account: '',
    },
    getters: {
        cookiesObject: (state) => state.cookiesObject,
        httpReq: (state) => state.httpReq,
        apiReq: (state) => state.apiReq,
        account: (state) => state.account,
        // leagueIDe: (state) => state.leagueIDe,
        // dateNtime: (state) => state.dateNtime,
        // gameCF: (state) => state.favsTe.length,
    },
}