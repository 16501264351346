<template>
  <div class="bg">
    <div style="background: white; padding-top: 20px; text-align: left;">
      <div class="container">
        <div class="row">
          <div
            class="col-xl col-md col-sm-12"
            style="margin-top: 10px; margin-bottom: 10px;"
          >
            <div class="row" v-if="courseId != 0">
              <div class="col">
                <small style="color: grey; font-weight: bold;">{{
                  $t("cCategory")
                }}</small>
                <h5
                  v-b-modal.courses
                  style="color: #1c50af; font-weight: bold;"
                >
                  {{ course.category.name }}
                </h5>
              </div>
              <div class="col">
                <small style="color: grey; font-weight: bold;">{{
                  $t("CTopic")
                }}</small>
                <h5
                  @click="getCategoryList2(course.category.id)"
                  v-b-modal.courses2
                  style="color: #1c50af; font-weight: bold;"
                >
                  {{ course.name }}
                </h5>
              </div>
            </div>
            <div v-else>
              <small style="color: grey; font-weight: bold;">{{
                $t("CTopic")
              }}</small>
              <h5 v-if="catId !== 0" style="color: #1c50af; font-weight: bold;">
                Category Exam
              </h5>
              <h5 v-else style="color: #1c50af; font-weight: bold;">
                General Exam
              </h5>
            </div>
          </div>
          <div
            class="col-xl col-md col-sm-12"
            style="text-align: center; margin-top: 10px; margin-bottom: 10px;"
          >
            <b-button
              v-if="courseId != 0"
              :to="{
                name: 'Practice',
                params: { id: id, courseId: courseId, catId: catId },
              }"
              style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; background: #48BF92; border: none;"
              ><img src="@/assets/testicons/pr.svg" alt="" />{{
                $t("gPractice")
              }}</b-button
            >
          </div>
        </div>
      </div>
    </div>
    <b-modal
      size="lg"
      ref="courses"
      centered
      id="courses"
      hide-footer
      hide-header
      body-class="loginModal"
    >
      <div
        style="text-align: right; margin-bottom: -30px; margin-right: -30px;"
      >
        <img
          src="@/assets/closeButt.svg"
          @click="hideModal"
          style="cursor: pointer;"
          alt=""
        />
      </div>
      <div class="d-block text-left">
        <div
          v-for="(cat, i) in catList"
          :key="i"
          style="border-left: 3px solid #E8EAEF;"
          v-bind:class="{ complete: cat.id < current }"
        >
          <img
            v-if="cat.id < current"
            src="@/assets/check1.svg"
            style="margin-left: -10px; margin-top: -9px;"
            alt=""
          />
          <div
            v-else
            style="height: 28px; width: 28px; background: #E8EAEF; border-radius: 50%; margin-left: 4px; text-align: center;"
          >
            {{ i + 1 }}
          </div>
          <div
            style="padding-left: 15px; padding-right: 10px; background: #fcfcfc; margin-left: 35px; border-radius: 10px; border-bottom: 3px solid #E8EAEF;"
          >
            <h4
              class="row"
              style="padding-top: 30px;"
              @click="getCategoryList2(cat.id)"
              v-b-toggle="'collapse-' + i"
            >
              <span class="col" style="margin-bottom: 10px; font-weight: 550;"
                >{{ cat.name }}
                <img
                  src="@/assets/arrDown.png"
                  class=""
                  style="width: 25px;"
                  alt=""/></span
              ><span v-if="cat.id == current" class="col-3"
                ><img src="@/assets/categoryIcons/progress.svg" alt=""
              /></span>
            </h4>
            <br />
            <p style="padding-bottom: 0px; margin-bottom: 15px;">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis
              obcaecati iusto autem dolore, repellendus similique.
            </p>
            <b-button
              v-if="cat.id <= current"
              @click="routGo(cat.id)"
              class="secButton"
              style="margin-bottom: 15px; margin-top: 15px;"
            >
              {{ $t("continue") }}
            </b-button>
          </div>
          <b-collapse :id="'collapse-' + i" class="mt-3 ml-4">
            <div
              v-for="(cat, i) in courseList"
              :key="i"
              style="padding-left: 15px; padding-right: 10px; background: #fcfcfc; margin-left: 35px; border-radius: 10px; margin-bottom: 10px; border-bottom: 3px solid #E8EAEF;"
            >
              <p class="row" style="padding-top: 15px;">
                <span class="col" style="font-weight: 550;">{{
                  cat.name
                }}</span>
              </p>
              <p style="padding-bottom: 0px;">
                Lorem ipsum dolor sit amet consectetur adipisicing elit
              </p>
              <b-button
                v-if="cat.id <= progList[0].course.id"
                class="secButton"
                :to="{
                  name: 'CourseMaterial',
                  params: {
                    progressId: id,
                    courseId: cat.id,
                    catId: catId,
                  },
                }"
                style="margin-bottom: 15px; "
              >
                {{ $t("continue") }}
              </b-button>
            </div>
          </b-collapse>
        </div>
      </div>
    </b-modal>
    <b-modal
      size="lg"
      ref="courses2"
      centered
      id="courses2"
      hide-footer
      hide-header
      body-class="loginModal"
    >
      <div
        style="text-align: right; margin-bottom: -30px; margin-right: -30px;"
      >
        <img
          src="@/assets/closeButt.svg"
          @click="hideModal2"
          style="cursor: pointer;"
          alt=""
        />
      </div>
      <div class="d-block text-left">
        <div
          v-for="(cat, i) in courseList"
          :key="i"
          style="padding-left: 15px; padding-right: 10px; background: #fcfcfc; border-radius: 10px; margin-bottom: 10px; border-bottom: 3px solid #E8EAEF;"
        >
          <p class="row" style="padding-top: 10px;">
            <span class="col" style="font-weight: 550;">{{ cat.name }}</span
            ><span v-if="cat.id == courseId" class="col-3"
              ><img src="@/assets/categoryIcons/progress.svg" alt=""
            /></span>
          </p>
          <p style="padding-bottom: 0px;">
            Lorem ipsum dolor sit amet consectetur adipisicing elit
          </p>
          <b-button
            v-if="cat.id <= progList[0].course.id"
            class="secButton"
            :to="{
              name: 'CourseMaterial',
              params: { progressId: id, courseId: cat.id, catId: catId },
            }"
            style="margin-bottom: 15px;"
          >
            {{ $t("continue") }}
          </b-button>
        </div>
      </div>
    </b-modal>

    <router-view :key="$route.path" style="margin-top: 35px;" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PracticePage",
  data() {
    return {
      number: "",
      id: "",
      courseId: 0,
      catId: 0,
      catList: [],
      courseList: [],
      progList: [],
      current: 0,
      course: {
        id: 22,
        category: {
          id: 3,
          name: "Loading",
          slug: "travel_transportation",
          status: true,
          listOrder: 3,
        },
        name: "Loading",
        listOrder: 1,
      },
    };
  },
  async mounted() {
    this.id = this.$route.params.progressId;
    this.courseId = this.$route.params.courseId;
    this.catId = this.$route.params.catId;

    this.getWords();
    this.getCategoryList();
    this.getProgressList();
  },

  methods: {
    // ...mapActions(["removeFavorite", "addToFavorites"]),
    getWords() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        this.apiReq +
          "/api/v1/words?courseId=" +
          this.courseId +
          "&token=" +
          this.cookiesObject.token +
          "&userProgressId=" +
          this.id,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          this.course = result.course;
        })
        .catch((error) => console.log("error", error));
    },
    // change() {
    //   this.$router.push({
    //     name: "CourseMaterial",
    //     params: { progressId: this.id, courseId: 22 },
    //   });
    // },
    hideModal() {
      this.$refs["courses"].hide();
    },
    hideModal2() {
      this.$refs["courses2"].hide();
    },
    getProgressList() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        this.apiReq + "/api/v1/learning/list?token=" + this.cookiesObject.token,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          this.progList = result.userProgressList.filter(
            (cat) => cat.id == this.id
          );
          this.current = this.progList[0].category.id;
        })
        .catch((error) => console.log("error", error));
    },
    getCategoryList() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(this.apiReq + "/api/v1/categories", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          this.catList = result.categories;
        })
        .catch((error) => console.log("error", error));
    },

    getCategoryList2(val) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        this.apiReq + "/api/v1/category/" + val + "/courses",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          this.courseList = result.courseList;
        })
        .catch((error) => console.log("error", error));
    },
    routGo(val) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        this.apiReq + "/api/v1/category/" + val + "/courses",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          this.$router.push({
            name: "CourseMaterial",
            params: { progressId: this.id, courseId: result.courseList[0].id },
          });
        })
        .catch((error) => console.log("error", error));
    },
  },

  components: {},
  props: {},

  computed: {
    ...mapGetters(["cookiesObject", "httpReq", "apiReq"]),
  },
};
</script>

<style scoped>
.navItem {
  color: #f7f8fc;
  font-size: 16px;
  font-weight: 500;
  padding: 9px;
  border-radius: 8px;
}
.navItemSelected {
  background: #48bf92;
}
.ddItem {
  text-align: right;
}

.secButton {
  font-weight: 600;
  border-radius: 5px;
  font-size: 15px;
  background: #eee1c4;
  border-color: #eee1c4;
  color: #f4a900;
}

.secButton:hover {
  font-weight: 600;
  border-radius: 5px;
  font-size: 15px;
  background: #f4a900;
  border-color: #f4a900;
  color: white;
}

.complete {
  border-left: 3px solid #48bf92 !important;
}
</style>

<style>
.loginModal {
  background: #f7f8fc;
  padding-right: 40px;
  padding-left: 40px;
  border-radius: 10px;
}
.modal-md {
  max-width: 400px !important;
}
</style>
