<template>
  <div
    class="container"
    style="padding-top: 10px; padding-bottom: 10px; min-height: 60vh;"
  >
    <div class="row">
      <div
        class="col-xl-4 col-md-4 col-sm-12 mr-3 ml-3"
        style="background: white; border-radius: 10px; padding: 30px; text-align: left; margin-bottom: 50px; height: 600px; overflow-y: scroll;"
      >
        <!-- <h4 style="margin-bottom: 30px; font-weight: 600; font-size: 16px;" >
        <img src="@/assets/flags/en.svg" style="" alt=""> English to 
        <img src="@/assets/flags/tu.svg" style="" alt=""> Russian 
      </h4> -->
        <p
          class="text"
          style="padding: 7px; border-radius: 7px;"
          v-for="(word, ind) in words"
          :key="ind"
          v-bind:class="{ text1: ind == current }"
          @click="
            (current = ind), (vol = word.audioMale), (vol2 = word.audioFemale)
          "
        >
          <span>{{ word.original }}</span>
        </p>
      </div>
      <div class="col-xl-7 col-md-7 col-sm-12">
        <div style="text-align: left;">
          <img
            v-if="words[current].photo"
            class="col"
            :src="
              'https://static.zuniac.com/language/photo/' + words[current].photo
            "
            style=""
            alt=""
          />
          <img
            v-else
            src="@/assets/noImage.png"
            class="col"
            style="height: 340px;"
            alt=""
          />
          <p style="margin-top: 10px; color: grey;">Original</p>
          <h4 style="border-bottom: 1px solid lightgrey; padding-bottom: 10px;">
            {{ words[current].original }}
          </h4>
          <p style="margin-top: 10px; color: grey;">Translation</p>
          <h4 style="margin-bottom: 30px;">
            {{ words[current].translation }}
            <img
              src="@/assets/categoryIcons/voice.svg"
              @click="audio2.pause(), (audio2.currentTime = 0), audio.play()"
              style=""
              alt=""
            />
            <img
              src="@/assets/categoryIcons/voice.svg"
              @click="audio.pause(), (audio.currentTime = 0), audio2.play()"
              style=""
              alt=""
            />
          </h4>
          <!-- <b-button class="mainButton" @click="nextCourse()" style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; margin-top: 20px;"> Finish course </b-button> -->
          <b-button
            style="border: none; font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; margin-right: 10px;"
            :disabled="current == 0"
            @click="
              current--,
                (vol = words[current].audioMale),
                (vol2 = words[current].audioFemale)
            "
            >{{ $t("previous") }}</b-button
          >
          <b-button
            class="mainButton"
            style="float: end; font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px;"
            v-if="current < words.length - 1"
            @click="
              current++,
                (vol = words[current].audioMale),
                (vol2 = words[current].audioFemale)
            "
            >{{ $t("next") }}</b-button
          >
          <b-button
            v-else
            :to="{
              name: 'Practice',
              params: { id: progressId, courseId: courseId, catId: catId },
            }"
            style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; background: #48BF92; border: none;"
            >{{ $t("gPractice") }}</b-button
          >
        </div>
      </div>
    </div>
    <div class="" style=""></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CourseMaterial",
  data() {
    return {
      progressId: "",
      courseId: 0,
      catId: 0,
      current: 0,
      vol: "",
      vol2: "",
      course: {},
      words: [
        {
          translation: "Loading",
          original: "Loading",
          photo: "words_1640025609.jpg",
        },
      ],
    };
  },
  async mounted() {
    this.progressId = this.$route.params.progressId;
    this.courseId = this.$route.params.courseId;
    this.catId = this.$route.params.catId;

    console.log(this.progressId, this.courseId, this.catId);

    this.getWords();
  },

  methods: {
    getWords() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        this.apiReq +
          "/api/v1/words?courseId=" +
          this.courseId +
          "&token=" +
          this.cookiesObject.token +
          "&userProgressId=" +
          this.progressId,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          this.words = result.wordList;
          console.log(result);
          this.vol = this.words[0].audioMale;
          this.vol2 = this.words[0].audioFemale;
          this.course = result.course;
        })
        .catch((error) => console.log("error", error));
    },

    nextCourse() {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
      };

      fetch(
        this.apiReq +
          "/api/v1/learning/next?token=" +
          this.cookiesObject.token +
          "&userProgressId=" +
          this.progressId,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          console.log(result);
          this.getWords();
        })
        .catch((error) => console.log("error", error));
    },
  },

  components: {},
  props: {},

  computed: {
    ...mapGetters(["cookiesObject", "httpReq", "apiReq"]),
    audio() {
      return new Audio("https://static.zuniac.com/language/audio/" + this.vol);
    },
    audio2() {
      return new Audio("https://static.zuniac.com/language/audio/" + this.vol2);
    },
  },
};
</script>

<style scoped>
.text1 {
  background: #e6e9ee;
  cursor: pointer;
}
.text:hover {
  background: #e6e9ee;
  cursor: pointer;
}
</style>
