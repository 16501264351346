<template>
  <div>
    <b-jumbotron class="jtron" fluid style="height: 95vh;">
      <template #header>
        <div
          class="col-xl-12 col-md-12 col-sm-12 mb-5"
          style="margin-top: 80px; font-weight: bold; font-size: 44px; text-align: center; color: white;"
        >
          {{ $t("ShowcaseHeader") }}
        </div>
      </template>

      <template #lead>
        <div
          class="col-xl-12 col-md-12 col-sm-12"
          style="font-size: 18px; text-align: center; color: white;"
        >
          <p style="margin-bottom: 20px;" class="mb-5">
            {{ $t("ShowcasePar") }}
          </p>

          <div class="row">
            <div class="col-xl-4 col-md-4 col-sm">
              <div class="" style="padding-bottom: 50px;">
                <p class="col-xl col-md col-sm-12" style="text-align: left;">
                  {{ $t("speak") }}
                </p>
                <b-form-select
                  class="col-xl col-md col-sm-12 ml-3 mr-3"
                  @change="fltr1"
                  v-model="selected"
                  text-field="name"
                  value-field="id"
                  :options="options"
                ></b-form-select>
              </div>
            </div>
            <div class="col-xl-4 col-md-4 col-sm">
              <div class="" style="padding-bottom: 50px;">
                <p class="col-xl col-md col-sm-12" style="text-align: left;">
                  {{ $t("learn") }}
                </p>
                <b-form-select
                  class="col-xl col-md col-sm-12 ml-3 mr-3"
                  v-model="selected1"
                  text-field="name"
                  value-field="id"
                  :options="options1"
                ></b-form-select>
              </div>
            </div>
            <div class="col-xl-3 col-md-3 col-sm-12">
              <b-button
                class="mainButton "
                id="arrow"
                to="/Category"
                style="font-weight: 600; border-radius: 5px; font-size: 15px; margin-bottom: 80px; padding: 8px 15px 8px 15px; margin-top: 42px;"
              >
                {{ $t("learn") }}
                <img src="@/assets/arrow.svg" id="arrow1" alt=""
              /></b-button>
            </div>
          </div>
        </div>
      </template>
    </b-jumbotron>
    <div class="container" style="padding-bottom: 20px;">
      <div class="row" style="text-align: left;">
        <div class="col-xl-6 col-md-6 col-sm-12">
          <img src="@/assets/about.svg" alt="" />
          <h4 style="font-weight: 600; font-size: 21px;">{{ $t("Wabout") }}</h4>
          <p>{{ $t("whatText") }}</p>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <img src="@/assets/for.svg" alt="" />
          <h4 style="font-weight: 600; font-size: 21px;">{{ $t("Wfor") }}</h4>
          <p>{{ $t("whoText") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Jtron",
  data() {
    return {
      selected: 1,
      selected1: 3,
      options: [],
      options1: [],
    };
  },
  async mounted() {
    this.lang();
  },

  methods: {
    fltr1(val) {
      this.options1 = this.options.filter((el) => el.id != val);
    },

    lang() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(this.apiReq + "/api/v1/languages", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          this.options = result.languageList;
          this.options1 = this.options.filter((el) => el.id != this.selected);
        })
        .catch((error) => console.log("error", error));
    },
  },

  components: {},
  props: {},

  computed: {
    ...mapGetters(["cookiesObject", "httpReq", "apiReq"]),
  },
};
</script>

<style scoped>
.jtron {
  background-image: linear-gradient(
      0deg,
      rgba(28, 80, 175, 0.8),
      rgba(28, 80, 175, 0.8)
    ),
    url("assets/jback.svg");
  background-size: cover;
}
</style>
