<template>
  <div class="" style="text-align: left;">
    <div class="container" style="padding-top: 50px; padding-bottom: 50px;">
      <span>
        <router-link
          class=""
          style="text-decoration: none;"
          id=""
          :to="{ name: 'Landing', params: {} }"
        >
          <span class="">Home</span>
        </router-link>
        |
        <span style="color: #F5AD0B;">Categories</span>
      </span>
      <div class="row">
        <div class="col-xl-7 col-md-7 col-sm-12" style="margin-top: 50px;">
          <h1
            v-if="progList.fromLanguage"
            style="font-weight: bold; padding-top: 30px;"
          >
            {{ progList.fromLanguage.name }}-{{ progList.toLanguage.name }}
            {{ $t("lCourse") }}
          </h1>
          <p>{{ $t("mCourseText") }}</p>
        </div>
        <div
          class="col-xl col-md col-sm-12"
          style="margin-top: 50px; text-align: center;"
        >
          <div class="row">
            <div class="col-md-6 col-xl-6 col-sm-12">
              <div
                style="background: #fcfcfc; padding: 25px; margin-bottom: 15px; border-radius: 7px;"
              >
                <small>{{ $t("speak") }}</small>
                <h4 v-if="progList.fromLanguage" style="font-weight: 550;">
                  <img
                    v-if="progList.fromLanguage.id == 1"
                    class=""
                    style="width: 25px;"
                    src="@/assets/flags/en.svg"
                  />
                  <img
                    v-else-if="progList.fromLanguage.id == 3"
                    class=""
                    style="width: 25px;"
                    src="@/assets/flags/ru.svg"
                  />
                  <img
                    v-else-if="progList.fromLanguage.id == 2"
                    class=""
                    style="width: 25px;"
                    src="@/assets/flags/fr.svg"
                  />
                  {{ progList.fromLanguage.name }}
                </h4>
              </div>
            </div>
            <div class="col-md-6 col-xl-6 col-sm-12">
              <div
                style="background: #fcfcfc; padding: 25px; margin-bottom: 15px; border-radius: 7px;"
              >
                <small>{{ $t("learn") }}</small>
                <h4 v-if="progList.fromLanguage" style="font-weight: 550;">
                  <img
                    v-if="progList.toLanguage.id == 1"
                    class=""
                    style="width: 25px;"
                    src="@/assets/flags/en.svg"
                  />
                  <img
                    v-else-if="progList.toLanguage.id == 3"
                    class=""
                    style="width: 25px;"
                    src="@/assets/flags/ru.svg"
                  />
                  <img
                    v-else-if="progList.toLanguage.id == 2"
                    class=""
                    style="width: 25px;"
                    src="@/assets/flags/fr.svg"
                  />
                  {{ progList.toLanguage.name }}
                </h4>
              </div>
            </div>
            <div class="col-md-6 col-xl-6 col-sm-12">
              <div
                style="background: #fcfcfc; padding: 25px; margin-bottom: 15px; border-radius: 7px;"
              >
                <small>{{ $t("started") }}</small>
                <h4 v-if="progList.startTime" style="font-weight: 550;">
                  {{ progList.startTime.slice(0, 10) }}
                </h4>
                <h4 v-else style="font-weight: 550;">Undefined</h4>
              </div>
            </div>
            <div class="col-md-6 col-xl-6 col-sm-12">
              <div
                style="background: #fcfcfc; padding: 25px; margin-bottom: 15px; border-radius: 7px;"
              >
                <small>{{ $t("finished") }}</small>
                <h4 v-if="progList.endTime" style="font-weight: 550;">
                  {{ progList.endTime.slice(0, 10) }}
                </h4>
                <h4 v-else style="font-weight: 550;">In progress</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container fluid">
      <div class="row" style="padding-bottom: 50px;">
        <div class="col">
          <div
            v-for="(cat, i) in catList"
            :key="i"
            style="border-left: 5px solid #E8EAEF;"
            v-bind:class="{ complete: cat.id < current }"
          >
            <img
              v-if="cat.id < current"
              src="@/assets/check1.svg"
              style="margin-left: -10px; margin-top: -9px;"
              alt=""
            />
            <div
              v-else
              style="height: 28px; width: 28px; background: #E8EAEF; border-radius: 50%; margin-left: 4px; text-align: center;"
            >
              {{ i + 1 }}
            </div>
            <div
              style="padding-left: 20px; padding-right: 20px; background: #fcfcfc; margin-left: 50px; border-radius: 10px;"
              class="shadow"
            >
              <h4
                class="row"
                style="padding-top: 20px;"
                @click="getCategoryList2(cat.id), (cl = !cl), (dropImage = i)"
              >
                <span
                  class="col-xl col-md col-sm-12"
                  style="margin-bottom: 10px;"
                  >{{ cat.name }}
                  <img
                    :src="cl && i == dropImage ? source2 : source1"
                    class=""
                    style="width: 25px; background: #eee1c4; border-radius: 50%; padding: 4px;"
                    alt=""/></span
                ><span
                  v-if="cat.id == current"
                  class="col-xl-2 col-md-2 col-sm-12"
                  ><img src="@/assets/categoryIcons/progress.svg" alt=""
                /></span>
              </h4>
              <br />
              <p style="padding-bottom: 0px; margin-bottom: 10px;">
                {{ cat.description }}
              </p>
              <b-button
                v-if="cat.id <= current"
                @click="routGo(cat.id)"
                class="secButton"
                style="margin-bottom: 15px; margin-top: 15px;"
              >
                {{ $t("continue") }}
              </b-button>
              <b-button
                v-else
                disabled
                style=" font-weight: 600; border-radius: 5px; font-size: 15px; background: #eee1c4; border-color: #eee1c4; color: grey; margin-bottom: 15px; margin-top: 15px;"
              >
                {{ $t("continue") }}
              </b-button>
            </div>
            <b-collapse :id="'collapse-' + i" class="row mt-3 ml-5">
              <div v-if="loader && curr === i" style="height: 500px;"></div>
              <div
                v-for="(cat, ind) in courseList[`c${cat.id}`]"
                :key="ind"
                class="col-md-4 col-xl-4 col-sm-12"
                v-else
              >
                <div
                  style="padding: 15px; background: #fcfcfc;  border-radius: 10px; margin-bottom: 10px; border-bottom: 3px solid #E8EAEF;"
                >
                  <p class="" style="">
                    {{ cat.name }}
                  </p>
                  <p
                    class="text-secondary"
                    v-if="cat.description"
                    style="font-size: 15px;"
                  >
                    {{ cat.description }}
                  </p>
                  <b-button
                    v-if="cat.id <= progList.course.id"
                    class="secButton"
                    :to="{
                      name: 'CourseMaterial',
                      params: {
                        progressId: id,
                        courseId: cat.id,
                        catId: cat.category.id,
                      },
                    }"
                    style="margin-bottom: 0px; margin-top: 0px;"
                  >
                    {{ $t("continue") }}
                  </b-button>
                  <b-button
                    v-else
                    disabled
                    style=" font-weight: 600; border-radius: 5px; font-size: 15px; background: #eee1c4; border-color: #eee1c4; color: grey;"
                  >
                    {{ $t("continue") }}
                  </b-button>
                </div>
              </div>
            </b-collapse>
          </div>
          <!-- <b-button v-if="progList[0].course.id == cat.id" class="mainButton mr-4 ml-4" :to="{ name: 'CourseMaterial', params: { id: id, courseId: courseId} }"  style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; margin-top: 20px;"> Continue </b-button> -->
        </div>
      </div>
    </div>

    <div class="container">
      <h3 style="font-weight: 600; margin-bottom: 30px;">
        {{ $t("startGen") }}
      </h3>
      <div
        class="row pt-4 pb-4"
        style="background: #fcfcfc; border-radius: 10px;"
      >
        <div class="col-xl-3 col-md-3 col-sm-12">
          <img src="@/assets/testIcon.svg" style="" alt="" />
        </div>
        <div class="col-xl-9 col-md-9 col-sm-12">
          <h3 style="font-weight: 600;">{{ $t("onMix") }}</h3>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Neque
            dolore, doloremque suscipit facere enim necessitatibus, pariatur
            asperiores debitis inventore tenetur ipsam consectetur nihil
            deserunt fugiat. In ab repellat suscipit esse.
          </p>
          <b-button
            class="mainButton"
            id="arrow"
            :to="{
              name: 'Practice',
              params: { progressId: this.id, courseId: 0, catId: 0 },
            }"
            style="font-weight: 600; border-radius: 5px; font-size: 15px; margin-bottom: 15px;"
          >
            {{ $t("sNow") }} <img src="@/assets/arrow.svg" id="arrow1" alt=""
          /></b-button>
        </div>
      </div>
      <h3 style="font-weight: 600; margin-bottom: 30px; margin-top: 30px;">
        {{ $t("alphabet") }}
      </h3>
      <div
        class="row pt-4 pb-4"
        style="background: #fcfcfc; border-radius: 10px; margin-bottom: 20px;"
      >
        <div class="col-xl-3 col-md-3 col-sm-12">
          <img src="@/assets/alphaIcon.svg" style="" alt="" />
        </div>
        <div class="col-xl-9 col-md-9 col-sm-12">
          <h3 style="font-weight: 600;">{{ $t("sTuned") }}</h3>
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Neque
            dolore, doloremque suscipit facere enim necessitatibus, pariatur
            asperiores debitis inventore tenetur ipsam consectetur nihil
            deserunt fugiat. In ab repellat suscipit esse.
          </p>
          <b-button
            class="mainButton"
            id="arrow"
            to="/Alphabet"
            style="font-weight: 600; border-radius: 5px; font-size: 15px; margin-bottom: 15px;"
          >
            {{ $t("sNow") }} <img src="@/assets/arrow.svg" id="arrow1" alt=""
          /></b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CategoryList",
  data() {
    return {
      id: "",
      catList: [],
      courseList: {},
      current: null,
      loader: null,
      curr: null,
      dropImage: "",
      cl: false,
      source1: require("@/assets/arrDown.png"),
      source2: require("@/assets/arrUp.png"),
      progList: [
        {
          id: 6,
          user: {
            id: 3,
            accountId: 225,
          },
          fromLanguage: {
            id: 1,
            code: "en",
            name: "English",
            direction: "ltr",
            status: 1,
          },
          toLanguage: {
            id: 2,
            code: "fr",
            name: "French",
            direction: "rtl",
            status: 0,
          },
          category: {
            id: 2,
            name: "General Conversations",
            slug: "general_conversations",
            status: true,
            listOrder: 2,
          },
          course: {
            id: 12,
            category: {
              id: 2,
              name: "General Conversations",
              slug: "general_conversations",
              status: true,
              listOrder: 2,
            },
            name: "Greetings",
            listOrder: 1,
          },
        },
      ],
    };
  },
  async mounted() {
    this.id = this.$route.params.id;

    this.getCategoryList();
    // this.getProgressList();
    this.progressData();
  },

  methods: {
    getCategoryList() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(this.apiReq + "/api/v1/categories", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          this.catList = result.categories;
        })
        .catch((error) => console.log("error", error));
    },

    getCategoryList2(val) {
      this.loader = true;

      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        this.apiReq + "/api/v1/category/" + val + "/courses",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (!this.courseList.val) {
            this.courseList[`c${val}`] = result.courseList;
          }

          this.curr = val - 1;
          this.$root.$emit("bv::toggle::collapse", `collapse-${val - 1}`);
          this.loader = false;
        })
        .catch((error) => console.log("error", error));
    },

    progressData() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        this.apiReq +
          "/api/v1/learning/" +
          this.id +
          "?token=" +
          this.cookiesObject.token,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          this.progList = result.userProgress;
          this.current = result.userProgress.category.id;
          console.log(result);
        })
        .catch((error) => console.log("error", error));
    },
    routGo(val) {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        this.apiReq + "/api/v1/category/" + val + "/courses",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          this.$router.push({
            name: "CourseMaterial",
            params: {
              progressId: this.id,
              courseId: result.courseList[0].id,
              catId: val,
            },
          });
        })
        .catch((error) => console.log("error", error));
    },
  },

  components: {},
  props: {},

  computed: {
    ...mapGetters(["cookiesObject", "httpReq", "apiReq"]),
  },
};
</script>

<style scoped>
.bg {
  background: #f7f8fc;
}

.active {
  border: 2px solid #48bf92 !important;
}
.secButton {
  font-weight: 600;
  border-radius: 5px;
  font-size: 15px;
  background: #eee1c4;
  border-color: #eee1c4;
  color: #f4a900;
}

.secButton:hover {
  font-weight: 600;
  border-radius: 5px;
  font-size: 15px;
  background: #f4a900;
  border-color: #f4a900;
  color: white;
}

.complete {
  border-left: 5px solid #48bf92 !important;
}
</style>
