<template>
  <div class="bg">
    <div class="container">
      <div class="row" style="padding-bottom: 20px; text-align: left;">
        <div class="col-xl-3 col-md-3 col-sm-12 pl-5">
          <div style="">
            <img src="@/assets/mainLogo.svg" alt="" /><span
              style="font-weight: bold; color: white; font-size: 20px;"
            >
              zunilingo</span
            >
          </div>
        </div>
        <div class="col-xl col-md col-sm pl-5" style="font-weight: 600;">
          <div class="row" style="text=align: left;">
            <router-link
              class=""
              style="text-decoration: none; margin-left: 10px;"
              id=""
              :to="{ name: 'About', params: {} }"
            >
              <p class="defText" style="font-size: 16px !important;">
                {{ $t("about") }}
                <span style="color: rgba(255,255,255,0.2);"> | </span>
              </p>
            </router-link>
            <router-link
              class=""
              style="text-decoration: none; margin-left: 10px;"
              id=""
              :to="{ name: 'Terms', params: {} }"
            >
              <p class="defText" style="font-size: 16px !important;">
                {{ $t("terms") }}
                <span style="color: rgba(255,255,255,0.2);"> | </span>
              </p>
            </router-link>
            <router-link
              class=""
              style="text-decoration: none; margin-left: 10px;"
              id=""
              :to="{ name: 'Privacy', params: {} }"
            >
              <p class="defText" style="font-size: 16px !important;">
                {{ $t("privacy") }}
              </p>
            </router-link>
          </div>
        </div>
        <div
          v-if="!cookiesObject.token"
          class="col-xl-3 col-md-3 col-sm-12 pl-5"
        >
          <b-button
            class="mainButton"
            id="arrow"
            to="/Category"
            style="font-weight: 600; border-radius: 5px; font-size: 15px; margin-bottom: 0px; padding: 8px 20px 8px 20px;"
          >
            {{ $t("learn") }} <img src="@/assets/arrow.svg" id="arrow1" alt=""
          /></b-button>
        </div>
        <p
          class="col-xl col-md col-sm-12 pl-5"
          style="color: grey; text-align: left; font-size: 16px !important;"
        >
          © {{ new Date().getFullYear() }} Zunilingo. All right reserved
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Footer",
  data() {
    return {};
  },
  async mounted() {},

  methods: {},

  components: {},
  props: {},

  computed: {
    ...mapGetters(["cookiesObject", "httpReq", "apiReq"]),
  },
};
</script>

<style scoped>
.bg {
  background: #1e2e4d;
  color: white;
  padding-top: 40px;
  padding-bottom: 20px;
}
.navItem {
  color: #f7f8fc;
  font-size: 16px;
  font-weight: 600;
  padding-right: 10px;
  padding-left: 10px;
}
.navItem:hover {
  color: #f5ad0b;
}
.ddItem {
  text-align: right;
}
</style>
