<template>
  <div class="pb-2" style="text-align: left; min-height: 81vh;">
    <div
      v-if="cookiesObject.token"
      class="container"
      style="padding-top: 50px; padding-bottom: 50px;"
    >
      <h3 style="font-weight: bold;">
        {{ $t("hello") }}, {{ account.username }}
      </h3>
      <div class="row" style="margin-top: 30px; margin-bottom: 30px;">
        <div class="col-xl-6 col-md-6 col-sm-12">
          <div class="row">
            <div class="col-xl-3 col-md-3 col-sm-12">
              <img
                v-if="account.avatar"
                :src="'https://static.zuniac.com/avatar/' + account.avatar"
                alt="Choose your avatar"
                style="margin-bottom: 20px; width: 120px;"
              />
              <div v-else>
                <img src="@/assets/profile.svg" style="width:150px;" />
              </div>
            </div>
            <div class="col-xl-9 col-md-9 col-sm-12">
              <p>
                <img src="@/assets/mobile.svg" style="" alt="" />
                {{ $t("mobNumb") }}: +{{ account.msisdn }}
              </p>
              <router-link
                class=""
                style="text-decoration: none;"
                id=""
                :to="{ name: 'Profile', params: {} }"
              >
                <p style="text-decoration: underline;">
                  <img src="@/assets/edit.svg" style="" alt="" />
                  {{ $t("edit") }}
                </p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="container"
      style="padding-top: 50px; padding-bottom: 50px;"
    >
      <h3 style="font-weight: bold;">Welcome to Zunilingo!</h3>
      <p>
        Register or login if you have an existing accout to proceed to courses
      </p>
    </div>
    <div class="container-fluid bg" style="padding-bottom: 50px;">
      <div class="container" style="padding-top: 20px;">
        <h3 style="font-weight: bold; padding-top: 20px; padding-bottom: 20px;">
          {{ $t("mCourse") }}
        </h3>
        <p style="margin-bottom: 50px;">{{ $t("mCourseText") }}</p>
        <!-- <p style="margin-bottom: 50px;">{{ $t("mCourseText") }}</p>
        <p style="margin-bottom: 50px;">{{ $t("mCourseText") }}</p>
        <p style="margin-bottom: 50px;">{{ $t("mCourseText") }}</p>
        <p style="margin-bottom: 50px;">{{ $t("mCourseText") }}</p>
        <p style="margin-bottom: 50px;">{{ $t("mCourseText") }}</p>
        <p style="margin-bottom: 50px;">{{ $t("mCourseText") }}</p>
        <p style="margin-bottom: 50px;">{{ $t("mCourseText") }}</p>
        <p style="margin-bottom: 50px;">{{ $t("mCourseText") }}</p>
        <p style="margin-bottom: 50px;">{{ $t("mCourseText") }}</p>
        <p style="margin-bottom: 50px;">{{ $t("mCourseText") }}</p>
        <p style="margin-bottom: 50px;">{{ $t("mCourseText") }}</p> -->

        <div
          class="col"
          style="padding: 15px; margin-bottom: 12px; background: #fdfeff; border-radius: 7px;"
          v-for="cat in progList"
          :key="cat.id"
        >
          <div class="row">
            <div
              class="col-xl-2 col-md-2 col-sm-12"
              style="text-align: center;"
            >
              <!-- <img src="@/assets/flags/en.svg"  style="width: 50px; margin-bottom: 10px;" alt=""> -->
              <div class="" style="margin-bottom: 10px;">
                <img
                  v-if="cat.fromLanguage.id == 1"
                  class=""
                  style="width: 50px; margin-bottom: 15px;"
                  src="@/assets/flags/en.svg"
                />
                <img
                  v-else-if="cat.fromLanguage.id == 2"
                  class=""
                  style="width: 50px; margin-bottom: 15px;"
                  src="@/assets/flags/fr.svg"
                />
                <img
                  v-else-if="cat.fromLanguage.id == 3"
                  class=""
                  style="width: 50px; margin-bottom: 15px;"
                  src="@/assets/flags/ru.svg"
                />

                <img
                  v-if="cat.toLanguage.id == 1"
                  style="width: 55px; margin-bottom: 0px; margin-left: -20px; border: 4px solid white; border-radius: 50%;"
                  src="@/assets/flags/en.svg"
                />
                <img
                  v-else-if="cat.toLanguage.id == 2"
                  style="width: 55px; margin-bottom: 0px; margin-left: -20px; border: 4px solid white; border-radius: 50%;"
                  src="@/assets/flags/fr.svg"
                />
                <img
                  v-else-if="cat.toLanguage.id == 3"
                  style="width: 55px; margin-bottom: 0px; margin-left: -20px; border: 4px solid white; border-radius: 50%;"
                  src="@/assets/flags/ru.svg"
                />
              </div>
            </div>
            <div class="col-xl col-md col-sm-12 headng">
              <router-link
                class=""
                style="text-decoration: none; color: inherit;"
                id=""
                :to="{ name: 'CategoryList', params: { id: cat.id } }"
              >
                <h5 class="" style="font-weight: bold;">
                  {{ cat.fromLanguage.name }}-{{ cat.toLanguage.name }}
                  {{ $t("lCourse") }}
                </h5>
                <p>
                  {{ $t("curAt") }} {{ cat.course.category.name }} -
                  {{ cat.course.name }}
                </p>
              </router-link>
            </div>
          </div>
        </div>

        <div
          v-if="cookiesObject.token"
          class="col"
          style="text-align: center; padding: 35px; margin-bottom: 30px; background: #fdfeff; border-radius: 7px; cursor: pointer; border: 2px dashed orange;"
        >
          <b-button
            class="secButton"
            :to="{ name: 'Selection', params: {} }"
            style="padding: 8px 20px 8px 20px;"
          >
            + {{ $t("addNew") }}
          </b-button>
        </div>
        <div
          v-else
          class="col"
          style="text-align: center; padding: 35px; margin-bottom: 150px; background: #fdfeff; border-radius: 7px; cursor: pointer; border: 2px dashed orange;"
        >
          <b-button
            v-b-modal.login-modal
            class="secButton"
            style="padding: 8px 20px 8px 20px;"
          >
            Login to Start
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Category",
  data() {
    return {
      catList: [],
      progList: [],
      current: 0,
      case: 0,
    };
  },

  async mounted() {
    this.getCategoryList();

    if (this.cookiesObject.token) {
      this.getProgressList();
    }

    this.check();

    setTimeout(() => {
      if (
        !this.account.username &&
        this.cookiesObject.token &&
        this.case == 1
      ) {
        this.$router.push({ name: "Profile", params: {} });
      }
    }, 1000);
  },

  methods: {
    //  ...mapActions(["getProfileG",]),

    getCategoryList() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(this.apiReq + "/api/v1/categories", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          this.catList = result.categories;
        })
        .catch((error) => console.log("error", error));
    },

    getProgressList() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        this.apiReq + "/api/v1/learning/list?token=" + this.cookiesObject.token,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          this.progList = result.userProgressList;
        })
        .catch((error) => console.log("error", error));
    },
    check() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        this.httpReq + "/check/zunilingo/" + this.cookiesObject.token,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.code == 200) {
            this.case = 1;
          } else {
            console.log(result);
          }
        })
        .catch((error) => console.log("error", error));
    },
  },

  components: {},
  props: {},

  computed: {
    ...mapGetters(["cookiesObject", "httpReq", "apiReq", "account"]),
  },
};
</script>

<style scoped>
.bg {
  background: #f7f8fc;
}

.secButton {
  font-weight: 600;
  border-radius: 5px;
  font-size: 15px;
  background: #eee1c4;
  border-color: #eee1c4;
  color: #f4a900;
}

.secButton:hover {
  font-weight: 600;
  border-radius: 5px;
  font-size: 15px;
  background: #f4a900;
  border-color: #f4a900;
  color: white;
}

.complete {
  border-left: 5px solid #48bf92 !important;
}

.headng:hover {
  color: #f4a900;
}
</style>
