<template>
<div>
</div> 
</template>

<script>

export default {
  name: 'Success',
  data(){
      return{
        cookiesObject: '',
        name: '',
        selected: '',
        }
  },
  async mounted(){
   
  },

  methods : {
     
  },

  components: {

  },
  props: {

  },
      
  computed: {

  },
}

</script>

<style scoped>
.bg{
    background: #F7F8FC;
}

</style>
