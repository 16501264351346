<template>
<div class="container" style="padding-top: 60px; padding-bottom: 60px;">
  <div class="row" style="background: white; border-radius: 10px;">
    <div class="col" style="text-align: left; ">
      <h4 style="margin-bottom: 50px; font-weight: bold; padding-left: 10px; padding-top: 30px;"> <img src="@/assets/flags/en.svg" style="width: 30px;" alt=""> USA </h4>
        <p style="border-bottom: 1px solid lightgrey; padding: 5px;" v-for="(some, i) in 8" :key="i"> <img src="@/assets/categoryIcons/voice.svg" style="" alt=""> Example </p>
    </div>
    <div class="col" style="text-align: left; ">
      <h4 style="margin-bottom: 50px; font-weight: bold; padding-left: 10px; padding-top: 30px;"> <img src="@/assets/flags/tu.svg" style="width: 30px;" alt=""> Turkce </h4>
        <p style="border-bottom: 1px solid lightgrey; padding: 5px;" v-for="(some, i) in 8" :key="i"> <img src="@/assets/categoryIcons/voice.svg" style="" alt=""> Example </p>
    </div>
  </div>
</div>
</template>

<script>

export default {
  name: 'Vocabulary',
  data(){
      return{
        cookiesObject: '',
        }
  },
  async mounted(){
   
  },

  methods : {
     
  },

  components: {

  },
  props: {

  },
      
  computed: {

  },
}

</script>

<style scoped>



</style>
