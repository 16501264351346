<template>
  <div class="bg">
    <div class="container" style="min-height: 83vh;">
      <div class="bg">
        <div class="container" style="padding-bottom: 50px;">
          <img
            src="@/assets/start.svg"
            style="margin-bottom: 50px; padding-top: 30px;"
            alt=""
          />
          <h1 style="font-weight: bold;">Let's start now</h1>
          <p>{{ $t("helpSent") }}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-6 col-md-6 col-sm-12">
          <div class="row" style="padding-bottom: 50px;">
            <p class="col-xl-6 col-md-6 col-sm-12">I speak</p>
            <b-form-select
              class="col-xl col-md col-sm-12 ml-3 mr-3"
              @change="fltr1"
              v-model="selected"
              :options="options"
            ></b-form-select>
          </div>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <div class="row" style="padding-bottom: 50px;">
            <p class="col-xl-6 col-md-6 col-sm-12">I want to learn</p>
            <b-form-select
              class="col-xl col-md col-sm-12 ml-3 mr-3"
              v-model="selected1"
              :options="options1"
            ></b-form-select>
          </div>
        </div>
      </div>
      <b-button
        class="mainButton"
        @click="startCourse()"
        style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; margin-bottom: 20px; margin-top: 50px;"
      >
        {{ $t("continue") }}
      </b-button>
    </div>

    <b-modal
      size="md"
      ref="select-modal"
      centered
      id="select-modal"
      hide-footer
      hide-header
      body-class="loginModal"
    >
      <div
        style="text-align: right; margin-bottom: -50px; margin-right: -30px;"
      >
        <img
          src="@/assets/closeButt.svg"
          @click="hideModal2"
          style="cursor: pointer;"
          alt=""
        />
      </div>
      <div class="d-block text-center">
        <h4
          style="text-align: center; padding-bottom: 30px;  font-weight: bold; color: #1E2E4D;"
        >
          Oops!
        </h4>
        <p style="text-align: center; margin-bottom: 30px; color: #5F6B81;">
          {{ response.toLowerCase() }}
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Selection",
  data() {
    return {
      name: "",
      selected: 1,
      response: "",
      selected1: 2,
      langList: [],
      options: [
        { value: 1, text: "English" },
        { value: 3, text: "Russian" },
        { value: 2, text: "French" },
      ],
      options1: [
        { value: 1, text: "English" },
        { value: 3, text: "Russian" },
        { value: 2, text: "French" },
      ],
    };
  },
  async mounted() {
    this.id = this.$route.params.id;

    this.lang();
    this.options1 = this.options.filter((el) => el.value != this.selected);
  },

  methods: {
    showModal() {
      this.$refs["select-modal"].show();
    },
    hideModal2() {
      this.$refs["select-modal"].hide();
    },
    lang() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(this.apiReq + "/api/v1/languages", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          this.langList = result.languageList;
        })
        .catch((error) => console.log("error", error));
    },

    startCourse() {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
      };

      fetch(
        this.apiReq +
          "/api/v1/learning/start?fromLanguageId=" +
          this.selected +
          "&toLanguageId=" +
          this.selected1 +
          "&token=" +
          this.cookiesObject.token,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.code == 200) {
            this.$router.push({
              name: "CategoryList",
              params: { id: result.id },
            });
          } else {
            this.response = result.message;
            this.showModal();
          }
        })
        .catch((error) => console.log("error", error));
    },

    fltr1(val) {
      this.options1 = this.options.filter((el) => el.value != val);
    },
  },

  components: {},
  props: {},

  computed: {
    ...mapGetters(["cookiesObject", "httpReq", "apiReq"]),
  },
};
</script>

<style scoped>
.bg {
  background: #f7f8fc;
}
.lang:hover {
  background: white;
  border-radius: 10px;
}
.selected {
  background: white;
  border-radius: 10px;
  box-shadow: 3px 5px 3px 3px rgba(197, 195, 195, 0.3);
}
</style>
