import Vue from 'vue'
import VueRouter from 'vue-router'
import Landing from '../components/views/main/Landing.vue'
import About from '../components/views/info/About.vue'
import Terms from '../components/views/info/Terms.vue'
import Privacy from '../components/views/info/Privacy.vue'
import Selection from '../components/views/starting/Selection.vue'
import Success from '../components/views/starting/Success.vue'
import Fail from '../components/views/starting/Fail.vue'
import Profile from '../components/views/profile/Profile.vue'
import Category from '../components/views/categories/Category.vue'
import CategoryList from '../components/views/categories/CategoryList.vue'
import Alphabet from '../components/views/categories/Alphabet.vue'
import PracticePage from '../components/views/practice/PracticePage.vue'
import CourseMaterial from '../components/views/practice/CourseMaterial.vue'
import Practice from '../components/views/practice/Practice.vue'
import PracticeN from '../components/views/practice/PracticeN.vue'
import Vocabulary from '../components/views/practice/Vocabulary.vue'

import global from '../store/global'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Landing',  
    component: Landing
  },
  {
    path: '/About',
    name: 'About',
    component: About
  },
  {
    path: '/Terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/Privacy',
    name: 'Privacy',
    component: Privacy
  },
  {
    path: '/Selection',
    name: 'Selection',
    component: Selection,
    beforeEnter: (to, from, next) => {
      if (global.state.cookiesObject.token) {
        next()
      } else {
        next(false)
      }
    }
  },
  {
    path: '/Success',
    name: 'Success',
    component: Success
  },
  {
    path: '/Fail',
    name: 'Fail',
    component: Fail
  },
  {
    path: '/Profile',
    name: 'Profile',
    component: Profile,
    beforeEnter: (to, from, next) => {
      if (global.state.cookiesObject.token) {
        next()
      } else {
        next(false)
      }
    }
  },
  {
    path: '/Category',
    name: 'Category',
    component: Category
  },
  {
    path: '/CategoryList/:id',
    name: 'CategoryList',
    component: CategoryList,
    beforeEnter: (to, from, next) => {
      if (global.state.cookiesObject.token) {
        next()
      } else {
        next(false)
      }
    }
  },
  {
    path: '/Alphabet',
    name: 'Alphabet',
    component: Alphabet
  },
  
  {
    name: 'PracticePage',
    path: '/PracticePage',
    component: PracticePage,
    redirect:'PracticePage/CourseMaterial',
    children:[
        {
          name: 'CourseMaterial',
          path:'CourseMaterial/category:catId/progress:progressId/Course:courseId',
          component: CourseMaterial,
        },
        {
          name: 'Practice',
          path:'Practice/category:catId/:progressId/Course:courseId',
          component: Practice,
        },
        {
          name: 'PracticeN',
          path:'PracticeN/:progressId',
          component: PracticeN,
        },
        {
          name: 'Vocabulary',
          path:'Vocabulary/:progressId',
          component: Vocabulary,
        },

    ],
},
]


const router = new VueRouter({
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes
})


export default router
