<template>
  <div>
    <b-navbar
      class="sticky-top "
      toggleable="lg"
      style="background: #1C50AF; height: 7vh; "
    >
      <b-navbar-brand to="/">
        <img src="@/assets/mainLogo.svg" alt="" /><span
          style="font-weight: bold; color: white;"
        >
          Zunilingo</span
        >
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"
        ><img
          src="@/assets/navbarTggl.svg"
          style="width: 50px; height: 20px;"
          alt=""
      /></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav right>
        <b-navbar-nav class="ml-auto">
          <b-nav-item
            v-if="!cookiesObject.token"
            v-on:click="scrollToElement()"
          >
            <span class="navItem"> {{ $t("categories") }} </span>
          </b-nav-item>
          <!-- <b-nav-item to="/Alphabet"> <span class="navItem"> {{ $t('alphabet') }} </span> </b-nav-item> -->
          <b-nav-item-dropdown text="Right align" no-caret right>
            <template #button-content>
              <span class="navItem">
                {{ $t("language") }}
                <img
                  v-if="$i18n.locale == 'ru'"
                  src="https://img.icons8.com/color/48/000000/russian-federation-circular.png"
                  style="width: 25px;"
                  alt=""
                />
                <img
                  v-else-if="$i18n.locale == 'en'"
                  src="https://img.icons8.com/color/48/000000/great-britain-circular.png"
                  style="width: 25px;"
                  alt=""
                />
                <img
                  v-else-if="$i18n.locale == 'fr'"
                  src="https://img.icons8.com/color/48/000000/france-circular.png"
                  style="width: 25px;"
                  alt=""
                />
                <img
                  v-else-if="$i18n.locale == 'az'"
                  src="https://img.icons8.com/color/48/000000/azerbaijan-circular.png"
                  style="width: 25px;"
                  alt=""
                />
              </span>
            </template>
            <b-dropdown-item
              class="ddItem"
              v-for="lang in langList"
              :key="lang.id"
              @click="$i18n.locale = lang.code"
              >{{ lang.name }}</b-dropdown-item
            >
          </b-nav-item-dropdown>
          <b-button
            v-if="!cookiesObject.token"
            class="mainButton mr-4 ml-4"
            v-b-modal.login-modal
            style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; "
          >
            {{ $t("login") }}
          </b-button>
          <b-nav-item-dropdown v-else text="left align" no-caret>
            <template #button-content>
              <b-button
                class="mainButton mr-4 ml-4"
                style="font-weight: 600; padding: 5px 20px 5px 20px; border-radius: 5px; font-size: 15px; "
                ><img src="@/assets/profile.svg" style="width: 25px;" alt="" />
                <span>{{ account.username }}</span>
              </b-button>
            </template>
            <!-- <b-dropdown-item class="" ><img src="@/assets/mobile.svg" style=""  alt=""> {{account.msisdn}} </b-dropdown-item> -->
            <b-dropdown-item class="" to="/Profile"
              ><img src="@/assets/edit.svg" style="" alt="" />
              {{ $t("settings") }}
            </b-dropdown-item>
            <b-dropdown-item class="" @click="logout()"
              ><img src="@/assets/lgOut.svg" style="" alt="" />
              {{ $t("logout") }}</b-dropdown-item
            >
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-modal
      size="md"
      ref="login-modal"
      centered
      id="login-modal"
      hide-footer
      hide-header
      body-class="loginModal"
    >
      <div
        style="text-align: right; margin-bottom: -30px; margin-right: -30px;"
      >
        <img
          src="@/assets/closeButt.svg"
          @click="hideModal"
          style="cursor: pointer;"
          alt=""
        />
      </div>
      <form @submit.prevent="hideModal(), status()" class="d-block text-center">
        <h4
          style="text-align: center; padding-top: 0px; font-weight: bold; color: #1E2E4D;"
        >
          Login now
        </h4>
        <p
          style="text-align: center; padding-top: 30px; padding-bottom: 30px; color: #5F6B81;"
        >
          Please enter your phone number to continue
        </p>
        <vue-tel-input
          style="border-radius: 5px;"
          class=""
          mode="international"
          @keyup.enter="hideModal(), status()"
          v-model="msisdn"
        ></vue-tel-input>
        <b-button
          class="mainButton"
          type="submit"
          @click="hideModal(), status()"
          style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; margin-top: 30px; margin-bottom: 30px;"
        >
          {{ $t("login") }}
        </b-button>
      </form>
    </b-modal>
    <b-modal
      size="md"
      ref="otp-modal"
      centered
      id="otp-modal"
      hide-footer
      hide-header
      body-class="loginModal"
    >
      <div
        style="text-align: right; margin-bottom: -30px; margin-right: -30px;"
      >
        <img
          src="@/assets/closeButt.svg"
          @click="hideModal2"
          style="cursor: pointer;"
          alt=""
        />
      </div>
      <div class="d-block text-center">
        <h4
          style="text-align: center; padding-top: 0px; font-weight: bold; color: #1E2E4D;"
        >
          {{ $t("verCode") }}
        </h4>
        <p style="text-align: center; padding-top: 30px; color: #5F6B81;">
          {{ $t("verRec") }}
        </p>
        <p style="color: #F4A900">"{{ msisdn }}"</p>
        <b-form-input
          id="input-1"
          v-model="otp"
          placeholder="Input otp here"
          @keyup.enter="verify()"
          maxlength="5"
        ></b-form-input>
        <span style="color: red;">{{ errorMsg }}</span> <br />
        <b-button
          class="mainButton"
          @click="verify()"
          style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; margin-top: 30px; margin-bottom: 30px;"
        >
          Submit
        </b-button>
      </div>
    </b-modal>
    <b-modal
      size="md"
      ref="activate-modal"
      centered
      id="activate-modal"
      hide-footer
      hide-header
      body-class="loginModal"
    >
      <div
        style="text-align: right; margin-bottom: -30px; margin-right: -30px;"
      >
        <img
          src="@/assets/closeButt.svg"
          @click="hideModal3"
          style="cursor: pointer;"
          alt=""
        />
      </div>
      <div class="d-block text-center">
        <h4
          style="text-align: center; padding-top: 0px; font-weight: bold; color: #1E2E4D;"
        >
          Activation
        </h4>
        <p style="text-align: center; padding-top: 30px; color: #5F6B81;">
          You want to activate service on:
        </p>
        <p style="color: #F4A900">"{{ cookiesObject.number }}"</p>
        <b-button
          class="mainButton"
          @click="activate()"
          style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; margin-top: 30px; margin-bottom: 30px;"
        >
          Activate
        </b-button>
      </div>
    </b-modal>
    <b-modal
      size="md"
      ref="mess-modal"
      centered
      id="mess-modal"
      hide-footer
      hide-header
      body-class="loginModal"
    >
      <div
        style="text-align: right; margin-bottom: -30px; margin-right: -30px;"
      >
        <img
          src="@/assets/closeButt.svg"
          @click="hideModal4"
          style="cursor: pointer;"
          alt=""
        />
      </div>
      <div class="d-block text-center">
        <h4
          style="text-align: center; margin-top: -40px; font-weight: bold; color: #1E2E4D;"
        >
          Sorry!
        </h4>
        <p style="text-align: center; padding-top: 30px; color: #5F6B81;">
          {{ warnMessage }}
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { VueTelInput } from "vue-tel-input";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Navbar",
  data() {
    return {
      msisdn: "",
      otp: "",
      periodId: "",
      transactionId: "",
      errorMsg: "",
      warnMessage: "",
      langList: [],
    };
  },
  async mounted() {
    if (this.cookiesObject.token) {
      this.check();
      this.getProfileG();
    }

    this.lang();

    if (!localStorage.getItem("genSettings")) {
      this.appData();
    }
  },

  methods: {
    ...mapActions(["getProfileG"]),

    hideModal() {
      this.$refs["login-modal"].hide();
    },
    showModal() {
      this.$refs["otp-modal"].show();
    },
    hideModal2() {
      this.$refs["otp-modal"].hide();
    },
    hideModal3() {
      this.$refs["activate-modal"].hide();
    },
    hideModal4() {
      this.$refs["mess-modal"].hide();
    },
    appData() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(this.apiReq + "/api/v1/settings", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            localStorage.setItem(
              "genSettings",
              JSON.stringify(result.settings)
            );

            this.$i18n.locale = result.settings.default_language_code;
          } else {
            console.log("General settings", result);
          }
        })
        .catch((error) => console.log("error", error));
    },
    status() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        this.httpReq +
          "/login/phone/status?appKeyword=zunilingo&msisdn=" +
          this.msisdn.substring(1).replace(/\s/g, ""),
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.code == "404") {
            if (result.periods.length == 1) {
              this.periodId = result.periods[0].id;
              document.cookie =
                "number =" + this.msisdn.substring(1).replace(/\s/g, "");
              this.showModal();
              this.reg();
            } else if (result.periods.length > 1) {
              alert(result.periods);
            } else {
              alert("Error");
            }
          } else if (result.code == "200") {
            document.cookie =
              "number =" + this.msisdn.substring(1).replace(/\s/g, "");
            this.showModal();
            this.login();
          } else if (result.code == "412") {
            this.warnMessage = "Number format you entered, is not supported";
            this.$refs["mess-modal"].show();
          } else if (result.code == "402") {
            this.warnMessage = "Registration with phone, is disabled";
            this.$refs["mess-modal"].show();
          } else {
            console.log(result);
          }
        })
        .catch((error) => console.log("error", error));
    },

    reg() {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
      };

      fetch(
        this.httpReq +
          "/login/phone/otp?appKeyword=zunilingo&msisdn=" +
          this.msisdn.substring(1).replace(/\s/g, "") +
          "&periodId=" +
          this.periodId,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.code == 200) {
            this.transactionId = result.transactionId;
          } else if (result.code == 402) {
            this.warnMessage = "Registration with phone, is disabled";
            this.$refs["mess-modal"].show();
          } else if (result.code == 404) {
            this.warnMessage = "Application is disabled";
            this.$refs["mess-modal"].show();
          } else if (result.code == 412) {
            this.warnMessage = "Number format you entered, is not supported";
            this.$refs["mess-modal"].show();
          } else if (result.code == 818) {
            this.warnMessage =
              "Subscription is disabled for now, try in a little while";
            this.$refs["mess-modal"].show();
          } else {
            console.log(result);
          }
        })
        .catch((error) => console.log("error", error));
    },

    login() {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
      };

      fetch(
        this.httpReq +
          "/login/phone/otp?appKeyword=zunilingo&msisdn=" +
          this.msisdn.substring(1).replace(/\s/g, ""),
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.code == 200) {
            this.transactionId = result.transactionId;
          } else if (result.code == 402) {
            this.warnMessage = "Registration with phone, is disabled";
            this.$refs["mess-modal"].show();
          } else if (result.code == 404) {
            this.warnMessage = "Application is disabled";
            this.$refs["mess-modal"].show();
          } else if (result.code == 412) {
            this.warnMessage = "Number format you entered, is not supported";
            this.$refs["mess-modal"].show();
          } else if (result.code == 818) {
            this.warnMessage =
              "Subscription is disabled for now, try in a little while";
            this.$refs["mess-modal"].show();
          } else {
            console.log(result);
          }
        })
        .catch((error) => console.log("error", error));
    },

    verify() {
      var requestOptions = {
        method: "PUT",
        redirect: "follow",
      };

      fetch(
        this.httpReq +
          "/login/phone/verify?otp=" +
          this.otp +
          "&transactionId=" +
          this.transactionId,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.code == 200) {
            this.token = result.token;
            document.cookie = "token =" + result.token;
            location.reload();
          } else if (result.code == 404) {
            this.warnMessage = "Transaction was not found";
            this.$refs["mess-modal"].show();
          } else if (result.code == 407) {
            this.warnMessage = "Transaction has expired";
            this.$refs["mess-modal"].show();
          } else if (result.code == 406) {
            this.errorMsg = "Wrong Pin, re-enter OTP";
          } else {
            console.log(result);
          }
        })
        .catch((error) => console.log("error", error));
    },

    check() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        this.httpReq + "/check/zunilingo/" + this.cookiesObject.token,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.code == 200) {
            console.log("Session start");
          } else if (result.code == 401) {
            console.log("Invalid Token");
          } else if (result.code == 404) {
            console.log("Application disabled");
          } else if (result.code == 411 || result.code == 409) {
            this.$refs["activate-modal"].show();
          } else {
            console.log(result);
          }
        })
        .catch((error) => console.log("error", error));
    },

    activate() {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
      };

      fetch(
        this.httpReq + "/activate/zunilingo?token=" + this.cookiesObject.token,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.code == 200) {
            console.log("Successful activation");
            location.assign("/");
          } else if (result.code == 401) {
            console.log("Not authorised");
          } else if (result.code == 402) {
            console.log("Error");
          } else if (result.code == 404) {
            console.log("App not active");
          } else if (result.code == 409) {
            console.log("Not enough balance");
          } else {
            console.log(result);
          }
        })
        .catch((error) => console.log("error", error));
    },

    lang() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(this.apiReq + "/api/v1/languages", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          this.langList = result.languageList;
          if (!localStorage.getItem("avLanguages")) {
            localStorage.setItem(
              "avLanguages",
              JSON.stringify(result.languageList)
            );
          }
        })
        .catch((error) => console.log("error", error));
    },

    scrollToElement() {
      if (window.innerWidth < 600) {
        window.scrollTo({
          top: 3600,
          left: 0,
          behavior: "smooth",
        });
      } else {
        window.scrollTo({
          top: 1750,
          left: 0,
          behavior: "smooth",
        });
      }
    },

    logout() {
      document.cookie = "token = ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
      document.cookie = "number = ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
      location.assign("/");
    },

    // setLan(){
    //      this.$i18n.locale = 'ru';
    //   },
  },

  components: {
    VueTelInput,
  },
  props: {},

  computed: {
    ...mapGetters(["cookiesObject", "httpReq", "apiReq", "account"]),
  },
};
</script>

<style scoped>
.navItem {
  color: #f7f8fc;
  font-size: 16px;
  font-weight: 600;
  padding-right: 10px;
  padding-left: 10px;
}
.navItem:hover {
  color: #f5ad0b;
}
.ddItem {
  text-align: right;
}

/* @media only screen and (max-width: 770px) {
  body{
    background: #212529;
  }
  .navDiv2{
    width: 100%;
    height: 60px;
    color: white;
    background: #1b1d20;  
}
  .MobileHide {
    display: none;
  }
}

@media only screen and (min-width: 770px) {
  .MobileShow {
    display: none;
  }

} */
</style>

<style>
.loginModal {
  background: #f7f8fc;
  padding-right: 40px;
  padding-left: 40px;
  border-radius: 10px;
}
.modal-md {
  max-width: 400px !important;
}
</style>
