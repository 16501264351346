<template>
<div>
    <div class="container" style="padding-top: 60px; padding-bottom: 60px;">
        <div class="row" style="">
            <div class="col">
                <div >
                    <img class="col-xl-7 col-md-7 col-sm-12" src="@/assets/quest.svg" style="" alt="">
                </div>
                <h3 style="padding-top: 30px; padding-bottom: 30px;">Some example question here?</h3>
                <div class="row" style="text-align: left;">
                    <div class="col-xl-6 col-md-6 col-sm-12 " style=" margin-bottom: 10px;">
                        <div class="banner row mr-1 ml-1" @click="selected = 1" v-bind:class="{ correct: selected == 1, color: selected != 0}" style="padding: 20px; border-radius: 7px;">
                            <div class="col-1 mr-1">
                                <img class="" v-if="selected == 1 || selected == 2" src="@/assets/check.svg" style="" alt=""> 
                                <span v-else class="numb">1</span> 
                            </div>
                            <span class="col"> Answer example one, something lorem </span>
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-6 col-sm-12 " style=" margin-bottom: 10px;">
                        <div class="banner row mr-1 ml-1" @click="selected = 2" v-bind:class="{ false: selected == 2}" style="padding: 20px; border-radius: 7px;">
                            <div class="col-1 mr-1">
                                <img class="" v-if="selected == 2" src="@/assets/ex.svg" style="" alt=""> 
                                <span v-else class="numb">2</span> 
                            </div>
                            <span class="col"> Answer example one, something lorem </span>
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-6 col-sm-12 " style=" margin-bottom: 10px;">
                        <div class="banner row mr-1 ml-1" style="padding: 20px; border-radius: 7px;">
                            <div class="col-1 mr-1">
                                <span class="numb">3</span> 
                            </div>
                            <span class="col"> Answer example one, something lorem </span>
                        </div>
                    </div>
                    <div class="col-xl-6 col-md-6 col-sm-12 " style=" margin-bottom: 10px;">
                        <div class="banner row mr-1 ml-1" style="padding: 20px; border-radius: 7px;">
                            <div class="col-1 mr-1">
                                <span class="numb">4</span> 
                            </div>
                            <span class="col"> Answer example one, something lorem </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>

export default {
  name: 'PracticeN',
  data(){
      return{
        selected: 0,
        }
  },
  async mounted(){
   
  },

  methods : {
     
  },

  components: {

  },
  props: {

  },
      
  computed: {

  },
}

</script>

<style scoped>

.numb{
    padding: 5px 8px 5px 8px; 
    background: lightgrey; 
    border-radius: 50%; 
    margin-left: 4px; 
    text-align: center;
}

.correct{
    background: white; 
    color: green;
}
.color{
    color: green;
}
.false{
    background: white; 
    color: #cb5e77;
}

</style>
