<template>
<div style="">
    <Jtron />
    <Wiki />
    <CategoryScroll />
    <Why />
    <!-- <Videos /> -->
</div> 
</template>

<script>
import {mapGetters} from 'vuex';

import Jtron from "./Jtron.vue"
import Wiki from "./Wiki.vue"
import Why from "./Why.vue"
// import Videos from "./Videos.vue"
import CategoryScroll from "./CategoryScroll.vue"

export default {
  name: 'Landing',
  data(){
      return{
        }
  },
  async mounted(){
    if (this.cookiesObject.token) {
      this.$router.push({ name: 'Category', params: {} });
    }
  },

  methods : {
     
  },

  components: {
    Jtron,
    Wiki,
    Why,
    // Videos,
    CategoryScroll,
  },
  props: {

  },
      
 computed: {
      ...mapGetters(["cookiesObject"]),
  },
}

</script>

<style scoped>


</style>
