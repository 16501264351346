<template>
  <div class="" style="text-align: left;">
    <div class="container" style="padding-top: 50px; padding-bottom: 50px;">
      <span>
        <router-link
          class=""
          style="text-decoration: none;"
          id=""
          :to="{ name: 'Landing', params: {} }"
        >
          <span class="">Home</span>
        </router-link>
        |
        <span style="color: #F5AD0B;">Profile</span>
      </span>
      <h1 style="font-weight: bold;">{{ $t("edit") }}</h1>
    </div>
    <div class="container-fluid" style="background: #F7F8FC;">
      <div class="container" style="padding-top: 20px;">
        <div class="col-xl-8 col-md-8 col-sm-12 pl-0">
          <h4>{{ $t("yAvatar") }}</h4>
          <p>{{ $t("chAvatar") }}</p>
          <b-button
            class="mainButton"
            v-b-modal.avatar-modal
            style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; border: 1px solid #F4A900; margin-right: 10px; margin-top: 10px;"
          >
            {{ $t("chooseAv") }}</b-button
          >
          <b-button
            class=""
            variant="light"
            style="font-weight: 600; padding: 8px 20px 8px 20px; border-radius: 5px; font-size: 15px; border: 1px solid darkgrey; margin-top: 10px;"
          >
            {{ $t("remove") }}
          </b-button>
          <div style="margin-top: 30px; margin-bottom: 30px;">
            <img
              v-if="account.avatar"
              :src="'https://static.zuniac.com/avatar/' + account.avatar"
              alt=""
              style="margin-top: 20px; border-radius: 50%;"
            />
            <img
              v-else
              src="@/assets/profile.svg"
              style="width: 150px; background-color: #F4A900; border-radius: 50%;"
              alt=""
            />
          </div>
          <p>
            {{ $t("cUname") }}
            <span v-if="account.username">{{ account.username }}</span>
            <span v-else> You do not have username! add it below.</span>
          </p>
        </div>
        <div class="row" style="padding-bottom: 50px;">
          <div class="col-xl-6 col-md-6 col-sm-12">
            <p style="margin-top: 15px;">{{ $t("eUname") }}</p>
            <b-form-input
              id="input-name"
              v-model="nname"
              placeholder="Your nickname"
              @keydown.enter.native="setNname()"
              maxlength="12"
            ></b-form-input>
          </div>
          <!-- <div class="col-xl-6 col-md-6 col-sm-12">
                    <p style="margin-top: 15px;">Fullname</p>
                    <b-form-input id="input-name" v-model="fname" placeholder="Your fullname"  maxlength="12" ></b-form-input> 
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <p style="margin-top: 15px;">Number</p>
                    <b-form-input id="input-name" v-model="number" placeholder="Your number"  maxlength="12" ></b-form-input> 
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <p style="margin-top: 15px;">Country</p>
                    <b-form-input id="input-name" v-model="country" placeholder="Your country"  maxlength="12" ></b-form-input> 
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <p style="margin-top: 15px;">Birth</p>
                    <b-form-input id="input-name" v-model="birth" placeholder="Your birth"  maxlength="12" ></b-form-input> 
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <p style="margin-top: 15px;">Gender</p>
                    <b-form-input id="input-name" v-model="gender" placeholder="Your gender"  maxlength="12" ></b-form-input> 
                </div> -->
        </div>
        <b-button
          @click="setNname()"
          class="mainButton"
          style="font-weight: 600; border-radius: 5px; font-size: 15px; margin-bottom: 15px;"
        >
          {{ $t("sChanges") }}
        </b-button>
        <p class="text-danger">{{ errorText }}</p>
      </div>
    </div>
    <b-modal
      size="lg"
      ref="avatar-modal"
      centered
      id="avatar-modal"
      hide-footer
      hide-header
      body-class="loginModal"
    >
      <div
        style="text-align: right; margin-bottom: -30px; margin-right: -30px;"
      >
        <img
          src="@/assets/closeButt.svg"
          @click="avatarModalHide()"
          style="cursor: pointer;"
          alt=""
        />
      </div>
      <div class="d-block text-center">
        <h4
          style="text-align: center; margin-top: -40px; font-weight: bold; color: #1E2E4D;"
        >
          Choose an Avatar!
        </h4>
        <div class="row">
          <div
            class="col-xl-3 col-md-3 col-sm-12 "
            v-for="avatar in avatarList"
            :key="avatar.id"
          >
            <img
              :src="'https://static.zuniac.com/avatar/' + avatar.path"
              alt=""
              style="margin-top: 20px; cursor: pointer;"
              @click="setAvatar(avatar.id), avatarModalHide()"
            />
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      size="md"
      ref="nname-modal"
      @hide="handleOk"
      centered
      id="nname-modal"
      hide-footer
      hide-header
      body-class="loginModal"
    >
      <!-- <div style="text-align: right; margin-bottom: -30px; margin-right: -30px;">
            <img src="@/assets/closeButt.svg" @click="nnameModalHide()" style="cursor: pointer;" alt="">
        </div> -->
      <div class="d-block text-center">
        <h4
          style="text-align: center; margin-top: 0px; font-weight: bold; color: #1E2E4D;"
        >
          Before we continue, lets set your nickname first!
        </h4>
        <div class="row" style="margin-bottom: 20px;">
          <b-form-input
            id="input-name"
            v-model="nname"
            placeholder="Choose your username"
            @keydown.enter.native="setNname()"
            maxlength="12"
            style="margin-top: 30px; margin-bottom: 30px;"
          ></b-form-input>
          <b-button
            @click="setNname()"
            class="mainButton m-auto"
            style="font-weight: 600; border-radius: 5px; font-size: 15px;"
          >
            Save Changes
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Profile",
  data() {
    return {
      avatarList: [],
      nname: "",
      fname: "",
      number: "",
      country: "",
      birth: "",
      gender: "",
      errorText: null,
    };
  },
  async mounted() {
    this.getAvatars();

    setTimeout(() => {
      if (!this.account.username) {
        this.nnameModalShow();
      }
    }, 500);
  },

  methods: {
    ...mapActions(["getProfileG"]),
    avatarModalHide() {
      this.$refs["avatar-modal"].hide();
    },
    nnameModalHide() {
      this.$refs["nname-modal"].hide();
    },
    nnameModalShow() {
      this.$refs["nname-modal"].show();
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      // Trigger submit handler
      // this.handleSubmit()
    },
    getAvatars() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        this.httpReq + "/avatars?token=" + this.cookiesObject.token,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          this.avatarList = result.avatars;
        })
        .catch((error) => console.log("error", error));
    },

    setAvatar(id) {
      var requestOptions = {
        method: "PUT",
        redirect: "follow",
      };

      fetch(
        this.httpReq +
          "/avatar?avatarId=" +
          id +
          "&token=" +
          this.cookiesObject.token,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          console.log(result);
          this.getProfileG();
        })
        .catch((error) => console.log("error", error));
    },
    setNname() {
      var requestOptions = {
        method: "PUT",
        redirect: "follow",
      };

      fetch(
        this.httpReq +
          "/account/username?token=" +
          this.cookiesObject.token +
          "&username=" +
          this.nname,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.code === 200) {
            console.log(result);
            this.getProfileG();
            this.$router.push({ name: "Category", params: {} });
          } else {
            this.errorText = result.message;
          }
        })
        .catch((error) => console.log("error", error));
    },
  },

  components: {},
  props: {},

  computed: {
    ...mapGetters(["cookiesObject", "httpReq", "apiReq", "account"]),
  },
};
</script>

<style scoped>
.bg {
  background: #f7f8fc;
}
</style>
