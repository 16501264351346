<template>
  <div
    class="container-fluid"
    style="padding-top: 70px; padding-bottom: 70px; background: #F7F8FC;"
  >
    <div class="container">
      <div>
        <h1 style="font-weight: bold; margin-bottom: 50px;">
          {{ $t("CourseHeader") }}
        </h1>
        <p>{{ $t("CourseText") }}</p>
      </div>
      <div class="row">
        <div
          class="col-xl-3 col-md-3 col-sm-12 mt-2"
          style="padding-top: 25px;"
          v-for="(cat, i) in catList"
          :key="i"
        >
          <div class="pad">
            <img :src="img + cat.photo" style="width: 100px;" alt="" />
            <h4 style="font-weight: 600;">{{ cat.name }}</h4>
            <p>{{ cat.courseCount }} {{ $t("categories") }}</p>
          </div>
        </div>
      </div>
    </div>
    <b-button
      v-if="val < 5"
      @click="(val = val + 25), getCategoryList()"
      style="margin-bottom: 15px; margin-top: 25px; background: transparent; border: none; color: orange; text-decoration: underline;"
    >
      {{ $t("load") }}
    </b-button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CategoryScroll",
  data() {
    return {
      catList: [],
      val: 4,
      img: "https://static.zuniac.com/language/photo/",
    };
  },
  async mounted() {
    this.getCategoryList();
  },

  methods: {
    getCategoryList() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(this.apiReq + "/api/v1/categories", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          this.catList = result.categories.slice(0, this.val);
        })
        .catch((error) => console.log("error", error));
    },
  },

  components: {},
  props: {},

  computed: {
    ...mapGetters(["cookiesObject", "httpReq", "apiReq"]),
  },
};
</script>

<style scoped>
.pad {
  background: white;
  padding: 30px;
  border-radius: 5px;
  height: 270px;
}

.pad:hover {
  margin-top: -5px !important;
  transition: 350ms;
  box-shadow: 0px 10px 20px 2px #bbb8b8;
}
</style>
